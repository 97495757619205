<template>
  <Modal :activeModal="activeModal" @closeModal="closeModal">
    <v-card>
      <v-toolbar flat class="modal-cadastro-toolbar">
        {{ $gettext('Relatório Lançamento de Caixa (Débitos)') }}
        <v-spacer></v-spacer>
        <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>

      <v-container fluid class="pb-0">
        <v-col>
          <v-card>
            <v-card-text>
              <v-row>
                <v-col class="pa-3 pt-0 pb-1">
                  <!-- Periodo: {{ rangeDate.dtInicial | data('DD/MM/YYYY') }} a {{ rangeDate.dtFinal | data }} -->
                  <strong>Data de Referência:</strong> {{ date }}
                </v-col>

                <v-col class="text-right pa-3 pt-0 pb-1">
                  <strong>Revendedor:</strong> {{ revendedor }} 
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-3 pt-0 pb-1">
                  <date />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-container>

      <v-col class="d-flex justify-center align-center" style="min-height: 300px; width: 100%;" v-if="loadingModal">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-col>

      
      <v-container fluid class="mt-0" v-else>
        <v-col>
          <div class="box-table">
            <v-data-table
              :headers="headers"
              :items="listCaixa"
              :items-per-page="5"
              width="300px"
              height="auto"
              flat
              dense>
              <!-- <template v-slot:body="{ items }">
                
              </template> -->

              <template v-slot:item.value="{ item }">
                {{ item.value | currency }}
              </template>


              <template slot="body.append">
                <tr class="">
                  <th>Total: </th>
                  <th>{{ total | currency }}</th>
                </tr>
              </template>
            </v-data-table>
          </div>
        </v-col>
      </v-container>
    </v-card>

  </Modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
// import { required } from 'vuelidate/lib/validators'
import Events from '@/core/service/events'
import variables from '@/assets/styles/helpers/_variables.scss'
import moment from 'moment'
import date from '@/views/components/date'

import validacao from './validacao'

const namespaceStore = 'historicoVendas'

export default {
  name: 'ModalCaixa',
  mixins: [validationMixin],
  components: {
    // HeaderModal: () => import('@/views/components/headerModal')
    Modal: () => import('@/views/components/modal'),
    date
  },
  data: () => ({
    activeModal: false,
    loading: false,
    entityId: null,
    dtFinal: null,
    dtInicial: null,
    lotteryGuesses: null,
    lotteryGames: null,
    loadingModal: false,
    nrJogo: null,
    revendedor: '',
    date: '',
    headers: [
      { sortable: false, class: 'table-header', text: 'Valor', value: 'value' },
      { sortable: false, class: 'table-header', text: 'Descrição', value: 'description' },
    ]
  }),
  
  computed: {
    ...mapGetters(namespaceStore, ['listaItens', 'totalItens', 'transmissoes', 'listCaixa']),
    ...validacao,
    variables: () => variables,
    isEdit () {
      return this.item.id ? !!1 : !!0
    },

    total () {
      return this.listCaixa.reduce((accumulator, currentValue) => {
        return accumulator + parseFloat(currentValue.value)
      }, 0)      
    }
  },
  mounted () {
    const self = this
    Events.$on('modal::historicoVendas::pgto', (e) => {
      self.activeModal = true
      self.date = this.displayDate(e.data, e.hasMonthOrDate)
      self.revendedor = e.entity.entity_cod

      self.loadingModal = true

      self.formatDate(e)

      self.getListCaixa({
        _addlFilter: {
          'entityId_=': e.entity.entity_id,
          'entryType:parentPath_<@': 'A.R.PGTR'
        },
        filterDtStart: moment(this.dtInicial).format('YYYY-MM-DD 00:00:00'),
        filterDtEnd: moment(this.dtFinal).format('YYYY-MM-DD 23:59:59')
      })
        .finally(() => self.loadingModal = false)
    })
  },
  methods: {
    ...mapActions(namespaceStore, ['getListCaixa', 'limparItem']),
    openModal () {
      this.activeModal = true
    },

    closeModal () {
      this.activeModal = false
      this.limparItem()
    },

    formatDate (data) {
      if (data.hasMonthOrDate === 2) {
        this.dtInicial = moment(data.data).startOf('month').format('YYYY-MM-DD')
        this.dtFinal = moment(data.data).endOf('month').format('YYYY-MM-DD')

        return
      } 

      this.dtInicial = moment(data.data).format('YYYY-MM-DD')
      this.dtFinal = moment(data.data).format('YYYY-MM-DD')
     },

    displayDate (date, type) {
      if (type === 2) return moment(date).format('MM/YYYY')

      return moment(date).format('DD/MM/YYYY')
    }
  },

  beforeDestroy () {
    Events.$off('modal::historicoVendas::pgto')
  }
}
</script>

<style lang="scss">
  @import '~@/assets/styles/components/formularios';
  
</style>